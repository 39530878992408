import * as React from 'react';
import { Classable } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import LOGO_SRC from '../../images/logo.png';
import { EntityLink, PAGE_HOME } from '@shapeable/web';

const ContainerStyles = breakpoints({
	base: css`
		height: 32px;
		margin-right: ${theme.UNIT(1)};
		
	`,
	tablet: css`
		height: 50px;
	`,
});

const ImageStyles = breakpoints({
	base: css`
		max-height: 100%;
	`,
});

const LinkStyles = breakpoints({
	base: css`
		
	`,
});


const My = {
	Container: styled.div`${ContainerStyles}`,
	Link: styled(EntityLink)`${LinkStyles}`,
	Image: styled.img`${ImageStyles}`
};

export const Logo: React.FC<Classable> = ({ className }) => (
  <My.Link entity={PAGE_HOME}>
	<My.Container className={className}>
		<My.Image src={LOGO_SRC} />
	</My.Container>
	</My.Link>
);
