import React from 'react';
import { IconComponent } from '@shapeable/types'

export const DataSetIcon: IconComponent = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 48 48">
  <g>
    <path d="M24,1C11.3,1,1,11.3,1,24c0,12.7,10.3,23,23,23s23-10.3,23-23C47,11.3,36.7,1,24,1z M24,43C13.5,43,5,34.5,5,24
      S13.5,5,24,5s19,8.5,19,19S34.5,43,24,43z"/>
    <circle cx="34" cy="36" r="1"/>
    <circle cx="14" cy="36" r="1"/>
    <circle cx="18" cy="36" r="1"/>
    <circle cx="22" cy="36" r="1"/>
    <circle cx="26" cy="36" r="1"/>
    <circle cx="30" cy="36" r="1"/>
    <circle cx="18" cy="40" r="1"/>
    <circle cx="22" cy="40" r="1"/>
    <circle cx="26" cy="40" r="1"/>
    <circle cx="30" cy="40" r="1"/>
    <circle cx="34" cy="32" r="1"/>
    <circle cx="14" cy="32" r="1"/>
    <circle cx="18" cy="32" r="1"/>
    <circle cx="22" cy="32" r="1"/>
    <circle cx="26" cy="32" r="1"/>
    <circle cx="30" cy="32" r="1"/>
    <circle cx="38" cy="32" r="1"/>
    <circle cx="10" cy="32" r="1"/>
    <circle cx="34" cy="28" r="1"/>
    <circle cx="14" cy="28" r="1"/>
    <circle cx="18" cy="28" r="1"/>
    <circle cx="22" cy="28" r="1"/>
    <circle cx="26" cy="28" r="1"/>
    <circle cx="30" cy="28" r="1"/>
    <circle cx="38" cy="28" r="1"/>
    <circle cx="10" cy="28" r="1"/>
    <circle cx="34" cy="24" r="1"/>
    <circle cx="14" cy="24" r="1"/>
    <circle cx="18" cy="24" r="1"/>
    <circle cx="22" cy="24" r="1"/>
    <circle cx="26" cy="24" r="1"/>
    <circle cx="30" cy="24" r="1"/>
    <circle cx="38" cy="24" r="1"/>
    <circle cx="10" cy="24" r="1"/>
    <circle cx="34" cy="20" r="1"/>
    <circle cx="34" cy="16" r="1"/>
    <circle cx="34" cy="12" r="1"/>
    <circle cx="14" cy="20" r="1"/>
    <circle cx="26" cy="20" r="1"/>
    <circle cx="30" cy="20" r="1"/>
    <circle cx="38" cy="20" r="1"/>
    <circle cx="38" cy="16" r="1"/>
    <circle cx="22" cy="20" r="1"/>
    <circle cx="26" cy="16" r="1"/>
    <circle cx="30" cy="16" r="1"/>
    <circle cx="22" cy="16" r="1"/>
    <circle cx="30" cy="12" r="1"/>
  </g>
  </svg>
);