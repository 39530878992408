import * as React from 'react'; // tslint:disable-line
import { Classable } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { IconButton, Icons, usePage, useMenu, useReady, useLogo, ShareButtons, CellLabel, useForm, useContributeForm } from '@shapeable/web';
import { ClipLoader as Spinner } from 'react-spinners';
import { Menu } from './menu';

const { ChevronRight } = Icons;

// -------- Types -------->

interface HeaderLayoutProps extends Classable {
  siteTitle?: string;
  explorerPath?: string;
  tagline?: string;
  menuFoot?: React.ReactNode;
}

export const HeaderLayoutDefaultProps: HeaderLayoutProps = {
  siteTitle: '',
};

type BodyProps = {
};

type MenuProps = {
  isVisible?: boolean;
}
// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    position: relative;
    height: 46px;
    overflow: visible;
    transition: height 0.25s;
  `,
  tablet: css`
    height: 61px;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    width: 100%;
    display: flex;
    height: 100%;
    z-index: 100;
    position: relative;
    justify-content: space-between;
    margin: 0 auto;
    background: rgba(255,255,255, 0.97);
    box-sizing: border-box;
    align-items: center;
    height: 48px;
    padding: ${theme.UNIT(2)} ${theme.UNIT(3)};
    box-shadow: 0px 2px 3px -4px rgba(0,0,0,0.8);
  `,
  tablet: css`
    height: 66px;
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)} ${theme.UNIT(4)} ${theme.UNIT(4)};
  `,
});

const StartStyles = breakpoints({
  base: css`
    display: flex;
    height: 28px;
    align-items: center;
  `,
});

const MenuButtonStyles = breakpoints({
  base: css`
    padding: 0;
    padding-right: ${theme.UNIT(4)};
    padding-left: ${theme.UNIT(1)};
    border-right: 1px solid ${theme.COLOR('line-mid')};
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
    box-sizing: content-box;
  `,
  tablet: css`
    width: 28px;
    height: 28px;

    svg {
      width: 28px;
      height: 28px;
    }
  `
});

const LogoContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-left: ${theme.UNIT(2)};
  `,
});


const EndStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
  `,
});

const MenuContainerStyles = breakpoints({
  base: css`
    margin: 0 auto;
    width: 100%;
  `,
});

const MenuStyles = breakpoints({
  base: css`
    position: absolute;
    top: -100vh;
    transition: top 0.3s;
    z-index: 60;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    border-bottom: 1px solid ${theme.COLOR('lineSubtle')};
    font-size: ${theme.FONT_SIZE(14)};
    ${({ isVisible }: MenuProps) => isVisible && css`
      top: 46px;
    `}

    @media print { 
      display: none;
    }
  `,
  landscape: css`
    border-right: 1px solid ${theme.COLOR('lineSubtle')};
    width: 286px;
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(14)};
    ${({ isVisible }: MenuProps) => isVisible && css`
      top: 66px;
    `}
  `,
});

const ShareButtonsStyles = breakpoints({
  base: css`
    display: none;
  `,
  tablet: css`
    display: flex;
  `,
});

const ShareLabelStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    margin-right: ${theme.UNIT(3)};
    display: none;
  `,
  tablet: css`
    display: flex;
  `,
});

const FeedbackButtonStyles = breakpoints({
  base: css`
    ${theme.REMOVE_BUTTON_STYLE()};
    font-family: ${theme.FONT('sans')};
    cursor: pointer;
    background-color: ${theme.COLOR('app-chrome')}; 
    color: ${theme.COLOR('secondary')};
    ${theme.FILL('secondary')};
    display: flex;
    align-items: center;
    &:hover {
      color: ${theme.COLOR('primary')};
      ${theme.FILL('primary')};
    }
  `,
  tablet: css`
    margin-left: ${theme.UNIT(4)};
    padding-left: ${theme.UNIT(4)};
    ${theme.V_DOT_BG()};
    height: 20px;
  `,
  desktop: css`
    display: none;
  `
});

const FeedbackButtonIconStyles = breakpoints({
  base: css`
    margin-left: ${theme.UNIT(2)};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.header<any>`${ContainerStyles}`,

  MenuContainer: styled.div<BodyProps>`${MenuContainerStyles}`,
    Menu: styled(Menu)<MenuProps>`${MenuStyles}`,

  Body: styled.div<BodyProps>`${BodyStyles}`,
  
  End: styled.div`${EndStyles}`,
    ShareLabel: styled(CellLabel)`${ShareLabelStyles}`,
    ShareButtons: styled(ShareButtons)`${ShareButtonsStyles}`,
    FeedbackButton: styled.button`${FeedbackButtonStyles}`,
      FeedbackButtonIcon: styled(ChevronRight)`${FeedbackButtonIconStyles}`,

  Start: styled.div`${StartStyles}`,
    LogoContainer: styled.div`${LogoContainerStyles}`,
    MenuButton: styled(IconButton)`${MenuButtonStyles}`,
      
  
};

export const HeaderLayout: React.FC<HeaderLayoutProps> = (props) => {
  const { className } = props;
  const { path } = usePage();

  const Logo = useLogo();

  const menu = useMenu();
  const { isReady, key } = useReady();
  
  // this can't use a toggle, because the button to hide the thing is outside the menu, which will already cause a hide via the standard useClickOutside hook in the menu
  const onClick = !menu.isVisible ? menu.showOnClick : () => {};

  const tagline = props.tagline || process.env.TAGLINE || '';
  
  const hasTagline = !!tagline;
  
  const { showOnClick } = useContributeForm();

  return (
    <My.Container className={className}>
      <My.MenuContainer>
        <My.Menu isVisible={menu.isVisible}>
        </My.Menu>
      </My.MenuContainer>
      <My.Body>
        <My.Start>
          {
            (isReady) ? 
            <My.MenuButton onClick={onClick} icon={<Icons.ThreeLines />} /> :
            <Spinner size={24} />
          }
          <My.LogoContainer>
            {Logo && <Logo />}
          </My.LogoContainer>
        </My.Start>

        <My.End>
          <My.ShareLabel>Share:</My.ShareLabel>
          <My.ShareButtons color="secondary" hoverColor="primary" />
            <My.FeedbackButton onClick={showOnClick}>Give Feedback<My.FeedbackButtonIcon /></My.FeedbackButton>
        </My.End>        

      </My.Body>
    </My.Container>
  );
};

HeaderLayout.defaultProps = HeaderLayoutDefaultProps;


