import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, useEntityOptions } from '@shapeable/web';
import { PageMainAsideLayout as CoreComponent } from '@shapeable/web';

/**
 * A proxy for the standard PageMainAsideLayout which overrides content styles
 */

// -------- Types -------->

export type PageMainAsideLayoutProps = Classable & HasChildren & { 
  entity: Page;
};

export const PageMainAsideLayoutDefaultProps: Omit<PageMainAsideLayoutProps, 'entity'> = {

};

// -------- Child Component Props -------->

type ContainerProps = {
  useAlphaLists: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    
    .shp-page-content-layout__intro {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.15em;

      p {
        font-weight: 500;
        margin-bottom: 1.5em
      }
    }

    p {
      font-weight: 300;
    }

    p + ul,
    p + ol {
      margin-top: -1.4em;
    }


    h1 {
      font-family: 'Signika Negative', sans-serif;
      margin-top: 2em;
      font-size: 1.65em;
      ${theme.H_DOT_BG_END()};
      color: ${theme.COLOR('dark')};
    }

    h2 {
      font-family: 'Signika Negative', sans-serif;
      font-size: 1.45em;
      font-weight: 600;
      margin-top: 1.5em;
      color: ${theme.COLOR('#528EC8')};
    }

    h3 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 700;
      font-size: 1.25em;
      margin-top: 1.5em;
      color: ${theme.COLOR('dark')};

      strong {
        font-weight: 700;
        color: ${theme.COLOR('dark')};
      }
    }

    h1 + h2 {
      margin-top: -0.5em;     
    }

    blockquote {
      font-family: 'IBM Plex Serif', sans-serif;
      font-size: 1.3em;
      font-weight: 700;
      padding-right: ${theme.UNIT(10)}; 
      line-height: 1.45em;
      margin: ${theme.UNIT(4)} 0 ${theme.UNIT(8)};
      color: ${theme.COLOR("primary")};

      p {
        font-family: 'IBM Plex Serif', sans-serif;
        font-weight: 500;
      }
    }

    p + blockquote {
      margin-top: -0.5em;
    }

    ul li {
      margin-bottom: 0.5em;
    }

    .shp-horizontal-scroll {
      width: calc(100vw - 16px);
      margin-left: -16px;
      margin-right: -16px;
    }

    ${({ useAlphaLists }: ContainerProps ) => useAlphaLists && css`
      ol {
        list-style: lower-alpha;
        
        ul {
          margin-left: -4px;
          padding-left: 0;
        }
        li li {
          list-style: none;
          &::before {
            content: none;
          }
        }
      }
    `}

    table {
      width: 100%;
      background-color: #EEF4FA;
      border: 8px solid #EEF4FA;
      
      th {
        padding: 8px 16px 8px 8px;
        vertical-align: bottom;
        
      }

      td {
        min-width: 100px;
        padding: 12px 16px 12px 8px;
        border-width: 0 0 1px 0;
        border-color: #FFF;
        font-weight: 300;

        strong, b {
          color: ${theme.COLOR('primary')};
        }
      }

      th {
        color: #969696;
        border-color: #FFF;
        border-width: 0 0 1px 0;
        background-color: #EEF4FA;

        strong, b {
          color: ${theme.COLOR('primary')};
        }
      }
    }
  
  `,
  tablet: css`
    blockquote {
      font-size: 1.5em;
    }

    .shp-horizontal-scroll {
      width: calc(100vw - 12px);
      margin-left: -20px;
      margin-right: -20px;
    }

  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(18)};
    .shp-horizontal-scroll {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  `
});


// -------- Components -------->

const My = {
  Container: styled(CoreComponent)<ContainerProps>`${ContainerStyles}`,
};

export const PageMainAsideLayout: React.FC<PageMainAsideLayoutProps> = (props) => {
  const entity = useEntity(props.entity);
  const options = useEntityOptions(entity);

  return (
    <My.Container useAlphaLists={options.useAlphaLists} asideRatio={0.25} {...props} />
  )
};

PageMainAsideLayout.defaultProps = PageMainAsideLayoutDefaultProps;