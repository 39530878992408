import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { Classable, HasChildren, IconComponent, IsFormInput } from '@shapeable/types';
import { Icon, Icons } from '@shapeable/web';

// -------- Types -------->

export type InputCheckboxProps = Classable & HasChildren & IsFormInput & {
  id?: string;
  name?: string;
  value?: string;
  valid?: boolean;
  focus?: boolean;
  checked: boolean;
  required?: boolean;
  label?: string | ReactNode;
  icon?: IconComponent;
}

export const InputCheckboxDefaultProps: InputCheckboxProps = {
  checked: false,
  name: 'checkbox',
  required: false,
  value: 'yes',
  valid: true,
  icon: Icons.Tick,
  onChange: () => {},
};


// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400;
    color: ${theme.COLOR('text')};
    font-size: 0.85em;
    font-family: ${theme.FONT('sans')};

    ${(props: any) => !props.valid && css`
      color: ${theme.COLOR('error')};
    `}

    a {
      color: ${theme.COLOR('primary')};
      text-decoration: none;

      &:hover {
        color: ${theme.COLOR('secondary')};
      }
    }
  `,
  desktop: css`
    font-size: 1em;
  `
});

const InputStyles = breakpoints({
  base: css`
    position: absolute;
    left: -9999px;
  `,
});

const LabelStyles = breakpoints({
  base: css`
  `,
});

const IconStyles = breakpoints({
  base: css`
    width: 14px;
    height: 14px;
    ${theme.FILL('primary')};
  `,
});

const CheckboxStyles = breakpoints({
  base: css`
    border: 2px solid ${theme.COLOR('secondary')};
    width: 22px;
    height: 22px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-left: ${theme.UNIT(3)};

    &:hover {
      border-color: ${theme.COLOR('link-hover')};
    }

    ${(props: any) => !props.valid && css`
      border-color: ${theme.COLOR('error')};
    `}
  `,
});




// -------- Components -------->

const My = {
  Label: styled.span`${LabelStyles}`,
  Icon: styled(Icon)`${IconStyles}`,
  Checkbox: styled.div<any>`${CheckboxStyles}`,
  Container: styled.div<any>``,
  ActualInput: styled.input``,
};

My.Container = styled.label<InputCheckboxProps>`
  ${ContainerStyles}
  &:hover ${My.Checkbox} {
    border-color: ${theme.COLOR('link-hover')};
  }
`;

My.ActualInput = styled.input`
  ${InputStyles}
  &:focus ~ ${My.Checkbox} {
    transition: border-color 0.25s;
    border-color: ${theme.COLOR('link-hover')};
  }

  &:focus ~ ${My.Label} {
    color: ${theme.COLOR('secondary')};
  }
`;


export const InputCheckbox: React.FC<InputCheckboxProps> = (props) => {
  const { className, value, name, id, onChange, label, icon, checked, valid, required, children } = props;

  const Icon = icon;
  const handleChange = onChange && ((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(checked ? '' : value);
  });
  
  return (
    <My.Container valid={valid} className={className}>
      <My.ActualInput
        name={id || name}
        checked={checked}
        value={value}
        onChange={handleChange}
        type="checkbox" />
      <My.Label>{label || children}{required && '*'}</My.Label>
      <My.Checkbox valid={valid}>
      {checked && <My.Icon><Icon /></My.Icon>}
      </My.Checkbox>
    </My.Container>
  )
};

InputCheckbox.defaultProps = InputCheckboxDefaultProps;
