import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, MainAside, EntityGrid, EntityGridFilterValuesChangeHandler, pageLayoutWithProviders, useAppState, useEntity, useFeedEntries } from '@shapeable/web';
import { FeedEntryCard } from '../entities/feed-entry-card';
import { PageBannerLayout } from '../entities/page-banner-layout';
import { FeedEntriesProvider } from '../../gatsby/providers/feed-entries-provider';

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const GridStyles = breakpoints({
  base: css`
    margin-top: 0;
    ${theme.H_DOT_BG()};
    padding-top: 2px;
    
    .shp-entity-grid__filters {
      margin-top: 0;
    }

    .shp-entity-grid__grid {
      margin-top: 0;
    }
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const MainAsideStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(6)} ${theme.UNIT(4)};
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-size: ${theme.FONT_SIZE(18)};
    padding-bottom: ${theme.UNIT(4)};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
  Banner: styled(PageBannerLayout)`${BannerStyles}`,
  MainAside: styled(MainAside)`${MainAsideStyles}`,
  Intro: styled(ContentNode)`${IntroStyles}`,
  Grid: styled(EntityGrid)`${GridStyles}`,
};

export const PageLayoutKeyResources = pageLayoutWithProviders(
  (props) => {
    const { className, children } = props;
    const entity = useEntity(props.entity);
    const [ appState, setAppState, patchAppState ] = useAppState();
    const { feedEntries: state } = appState;
    const filters = (state && state.filters) || {};

    const feedEntries = useFeedEntries();

    const onFilterValuesChange: EntityGridFilterValuesChangeHandler = (values) => {
      const newState = { 
        feedEntries: { 
          ...(appState.feedEntries || {}),
          filters: values,
        }
      };
      patchAppState(newState);
    };

    return (
      <My.Container className={className}>
        {children}
        <My.Banner entity={entity} />
        <My.MainAside
          main={
            <>
            <My.Intro entity={entity.intro} />
            <My.Grid 
              items={feedEntries} 
              filterValues={filters}
              filterFieldNames={["topics", "subTopics"]}
              card={FeedEntryCard}
              onFilterValuesChange={onFilterValuesChange}
            />
            </>
          }
        />
      </My.Container>
    )
  },
  FeedEntriesProvider
);
