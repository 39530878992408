import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import {
  usePage, MenuItemLink, DownloadLink, CopyrightPrivacyLabel, CellLabel, 
  ShareButtons, useMainMenu, useMenu, useActiveEntity, isSameEntityPath,
  MenuItemsList,
} from '@shapeable/web';

// -------- Types -------->

export type MenuProps = Classable & HasChildren & {
  privacyPath?: string;
  head?: React.ReactNode;
  foot?: React.ReactNode;
}

export const MenuDefaultProps: MenuProps = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    box-sizing: border-box;
    padding: ${theme.UNIT(2)} ${theme.UNIT(3)} ${theme.UNIT(4)};
    background: ${theme.COLOR('light')};
  `,
  tablet: css`
    padding: ${theme.UNIT(2)} ${theme.UNIT(4)} ${theme.UNIT(4)};
  `,
});

const ItemsStyles = breakpoints({
  base: css`
  `,
});


const FooterStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)} 0;
    border-top: 1px solid ${theme.COLOR('line-subtle')};
    a {
      color: ${theme.COLOR('primary')};
      text-decoration: none;
    }
  `,
});

const DownloadLinkStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
    margin-bottom: ${theme.UNIT(3)};
    width: 100%;
    box-sizing: border-box;
    span {
      font-weight: 400;
    }
  `,
});

const CopyrightPrivacyStyles = breakpoints({
  base: css`
    margin-bottom: 0;
  `,
});

const ShareLabelStyles = breakpoints({
  base: css`
    
  `,
});

const ShareButtonsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<any>`${ContainerStyles}`,
    DownloadLink: styled(DownloadLink)`${DownloadLinkStyles}`,

    ShareLabel: styled(CellLabel)`${ShareLabelStyles}`,
    ShareButtons: styled(ShareButtons)`${ShareButtonsStyles}`,
    Items: styled(MenuItemsList)`${ItemsStyles}`,
    
    Footer: styled.div`${FooterStyles}`,

    CopyrightPrivacy: styled(CopyrightPrivacyLabel)`${CopyrightPrivacyStyles}`,
};

export const Menu: React.FC<MenuProps> = (props) => {
  const { className, privacyPath, children } = props;
  const menu = useMenu();
  const mainMenu = useMainMenu();
  const page = usePage();
  const ref = menu.useClickOutside();
  const currentEntity = useActiveEntity();

  return (
    <My.Container ref={ref} className={className}>
      <My.DownloadLink url={process.env.PDF_URL} backgroundColor="secondary" hoverBackgroundColor="primary" />
      <My.Items entity={mainMenu} />
      <My.Footer>
        <My.ShareButtons color="secondary" hoverColor="primary" />
        <My.CopyrightPrivacy privacyPath={privacyPath} />
        {children}
      </My.Footer>
    </My.Container>
  )
};

Menu.defaultProps = MenuDefaultProps;