import '../global.css';
import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme, themes } from '@shapeable/theme';
import { 
  PageContextInfo, LogoProvider, ThemeProvider, EmbedComponentsProvider, ShareContent, 
  NextMenuItemLink, useContributeForm, useCookieBanner, useReady, 
  CookieBanner, MainAside, LayoutsProvider
} from '@shapeable/web';

import { Logo } from './elements/logo';
import { HeaderLayout } from './elements/header-layout';
import { PageFooterLayout } from './elements/footer-layout';
import { ContributeFormPanel } from './elements/contribute-form-panel';
import { AsideLayout } from './elements/aside-layout';
import * as pageLayouts from './page-layouts';

export const SHARE_PANEL_WIDTH_BASE = 320;
export const SHARE_PANEL_WIDTH = 500;

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
  pageContext?: PageContextInfo;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

type ContainerProps = {
  disableScroll?: boolean;
};

type FullscreenProps = {
  isFullscreen?: boolean;
  isConstrained?: boolean;
};

type ContributeFormPanelProps = {
  isVisible?: boolean;
};


// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    flex-direction: column;
    display: flex;
    align-items: center;

    ${({ disableScroll }: ContainerProps ) => disableScroll && css`
      overflow: hidden;
      height: 100vh;
      padding-right: 15px;
    `}

  `,
});


const HeaderStyles = breakpoints({
  base: css`
    min-width: ${theme.MIN('content-width')};
    width: 100%;
    z-index: 80;
    height: 46px;
    position: fixed;
    max-width: 1400px;
  `,
  tablet: css`
    height: 64px;
  `,
});


const HeaderSpaceStyles = breakpoints({
  base: css`
    height: 46px;
  `,
  tablet: css`
    height: 64px;
  `,
});

const FooterStyles = breakpoints({
  base: css`
    
  `,
});

const BodyStyles = breakpoints({
  base: css`
    max-width: 1400px;
    background: #FFF;
    padding-bottom: ${theme.UNIT(14)};

  `,
});

const ShareContentStyles = breakpoints({
  base: css`
    
  `,
});

const NextPageStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(6)};

    a {
      span {
        color: ${theme.COLOR('secondary')};
        ${theme.FILL('secondary')};
      }

      &:hover {
        span {
          color: ${theme.COLOR('primary')};
        }
      }
    }
  `,
  tablet: css`
    margin-top: 0;
    padding-bottom: ${theme.UNIT(0)};
    padding-top: ${theme.UNIT(1)};
    padding-left: ${theme.UNIT(6)};
    margin-left: ${theme.UNIT(6)};
    ${theme.V_DOT_BG()};
  `
});


const ContributeFormPanelStyles = breakpoints({
  base: css`
    position: fixed;
    left: 100%;
    z-index: 100;
    transition: left 0.3s;
    width: 100%;
    height: 100%;
    top: 0;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 0%;
    `}
  `,
  tablet: css`

  `,
  desktop: css`
    width: 50%;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 50%;
    `}
  `,
});


const CookieBannerStyles = breakpoints({
  base: css`
    position: fixed;
    bottom: 0;
    left: 0;
    font-weight: 400;
    width: 100%;
    background: #EBEBEB;
    font-family: ${theme.FONT('sans')};
  `,
});


const ControlsStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(4)};
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    flex-direction: row;
    align-items: flex-start;
    
  `,
  desktop: css`
    width: 66.66667%;
  `,
});

const ContentStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Header: styled(HeaderLayout)`${HeaderStyles}`,
  HeaderSpace: styled.div<any>`${HeaderSpaceStyles}`,
  
  Body: styled.div`${BodyStyles}`,
    Content: styled.div`${ContentStyles}`,
      ContributeFormPanel: styled(ContributeFormPanel)<ContributeFormPanelProps>`${ContributeFormPanelStyles}`,
      ShareContent: styled(ShareContent)`${ShareContentStyles}`,
  
    Footer: styled(PageFooterLayout)`${FooterStyles}`,
  NextPage: styled(NextMenuItemLink)`${NextPageStyles}`,
  Controls: styled.div`${ControlsStyles}`,

  CookieBanner: styled(CookieBanner)`${CookieBannerStyles}`,

};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children, path, pageContext } = props;
  const themeOverrides = {
    font: {
      family: {
        serif: "'Signika Negative', serif",
        sans: `"Source Sans Pro", sans-serif`,
        heading: `"Source Sans Pro", sans-serif`,
        copy: `"Source Sans Pro", sans-serif`,
      }
    },
    color: {
      navigationActive: '#F58220',
      secondary: '#528EC8',
    },
    background: {
      formPanel: '#EFEEEE',
    },
  };

  const contributeForm = useContributeForm();

  const { isReady } = useReady();

  const [ agree, setAgree ] = useCookieBanner();

  let showCookieBanner = false;

  if (typeof window !== undefined) {
    showCookieBanner = !agree && isReady;
  }

  return (

    <LayoutsProvider value={{
      pageLayouts: {
        ...pageLayouts,
        HomeLayout: pageLayouts.PageLayoutHome,
        ContentLayout: pageLayouts.PageLayoutContent,
      },
      defaultPageLayout: pageLayouts.PageLayoutContent,
    }}>

    <MainAside.AsideFoot value={() => <AsideLayout />}>

    <LogoProvider value={Logo}>
    <EmbedComponentsProvider>
    <ThemeProvider theme="OC_THEME" overrides={themeOverrides}>
    <My.Container disableScroll={contributeForm.isVisible}>
    <My.Body>
    <My.Header />
    <My.HeaderSpace />
      <My.Content>
        <My.ContributeFormPanel isVisible={contributeForm.isVisible} />

      {children}
      <My.Controls>
        <My.ShareContent color="secondary" hoverColor="primary" />
        <My.NextPage />
      </My.Controls>
      </My.Content>
    </My.Body>
    <My.Footer />

    {
      showCookieBanner &&
      <My.CookieBanner onButtonClick={() => setAgree(true)} />
    }

    </My.Container>
    </ThemeProvider>
    </EmbedComponentsProvider>
    </LogoProvider>

    </MainAside.AsideFoot>

    </LayoutsProvider>

  );
  
};

Layout.defaultProps = LayoutDefaultProps;

