import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { FeedEntriesProvider as Provider } from '@shapeable/web'
import { sortBy, reverse } from 'lodash';
import { platformProviderValue } from '@shapeable/web';

const FEED_ENTRIES_QUERY = graphql`
  query PlatformFeedEntriesQuery {
    platform {
      allFeedEntries(options: { ignoreSiteHeaders: true }) {
        id name slug author __typename url published websiteName summary { text }
        topics { id name slug __typename _schema { label pluralLabel } }
        subTopics { id name __typename _schema { label pluralLabel } }
        openGraph { image { url static { childImageSharp { gatsbyImageData } } } }
      }
    }
  }`;

export const FeedEntriesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ reverse(sortBy( platformProviderValue( useStaticQuery(FEED_ENTRIES_QUERY), 'allFeedEntries' ), 'published')) }>{children}</Provider>;
};
