import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { MenuItemsList, DownloadLink, Menu, useMainMenu } from '@shapeable/web';

// -------- Types -------->

export type AsideMenuProps = Classable & HasChildren & {
}

export const AsideMenuDefaultProps: AsideMenuProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: none;
  `,
  tablet: css`
    display: block;
  `,
});

const MenuStyles = breakpoints({
  base: css`
    padding: 0 !important;

    .shp-menu-items-list__item-link {
      padding-left: ${theme.UNIT(3)};
      padding-right: ${theme.UNIT(3)};
    }
  `,
});

const DownloadLinkStyles = breakpoints({
  base: css`
    width: 100%;
    span {
  font-weight: 400;
    }
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Menu: styled(MenuItemsList)`${MenuStyles}`,
  DownloadLink: styled(DownloadLink)`${DownloadLinkStyles}`,
};

export const AsideMenu: React.FC<AsideMenuProps> = (props) => {
  const { className } = props;
  const mainMenu = useMainMenu();

  return (
    <My.Container className={className}>
      <My.DownloadLink url={process.env.PDF_URL} backgroundColor="secondary" hoverBackgroundColor="primary" />
      <My.Menu entity={mainMenu} />
    </My.Container>
  )
};

AsideMenu.defaultProps = AsideMenuDefaultProps;