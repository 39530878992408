import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity, FeedEntry, SubTopic, Topic } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { coalesce, EntityTextValue, EntityTextValueList, formatDateString, useEntity, EntityCard } from '@shapeable/web';

// -------- Types -------->

export type FeedEntryCardProps = Classable & HasChildren & { 
  entity?: FeedEntry & { topics: Topic[], subTopics: SubTopic[] };
};

export const FeedEntryCardDefaultProps: Omit<FeedEntryCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const CardStyles = breakpoints({
  base: css`
  `,
});

const LinkedStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
  `,
});

const LinkedListStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
  `,
});



// -------- Components -------->

const My = {
  Card: styled(EntityCard)<ContainerProps>`${CardStyles}`,
    Linked: styled(EntityTextValue)`${LinkedStyles}`,
    LinkedList: styled(EntityTextValueList)`${LinkedListStyles}`,

};

export const FeedEntryCard: React.FC<FeedEntryCardProps> = (props) => {
  const { className, children, entity, ...rest } = props;
  
  const { published, author, topics, subTopics } = entity;
  
  const label = coalesce(entity, ['subTopic.name']) || 'Article';
  
  return (
    <My.Card {...rest} label={label} entity={entity} href={entity.url} target="_blank" className={className}>
      {
        published && 
        <My.Linked label="Published: ">{formatDateString(published, 'Do MMM YYYY')}</My.Linked>
      }
      {
        author &&
        <My.Linked label="Author: ">{author}</My.Linked>
      }
      {
        topics && !!topics.length &&
        <My.LinkedList items={topics} />
      }
      {
        subTopics && !!subTopics.length &&
        <My.LinkedList items={subTopics} />
      }
    {children}
    </My.Card>
  )
};

FeedEntryCard.defaultProps = FeedEntryCardDefaultProps;