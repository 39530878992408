import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContributeButton } from '@shapeable/web';
import { AsideMenu } from './aside-menu';
import { DataSetIcon } from './data-set-icon';
import { GeoMapsLink } from './geo-maps-link';

// -------- Types -------->

export type AsideLayoutProps = Classable & HasChildren & {
}

export const AsideLayoutDefaultProps: AsideLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: none;
    position: sticky;
    top: 92px;
  `,
  desktop: css`
    display: block;
  `,
});

const ContributeButtonStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(6)};
    font-size: ${theme.FONT_SIZE(16)};
  `,
});

const MenuStyles = breakpoints({
  base: css`
    
  `,
});

const GeoMapsLinkStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  ContributeButton: styled(ContributeButton)`${ContributeButtonStyles}`,
  Menu: styled(AsideMenu)`${MenuStyles}`,
  GeoMapsLink: styled(GeoMapsLink)`${GeoMapsLinkStyles}`,
};

export const AsideLayout: React.FC<AsideLayoutProps> = (props) => {
  const { className } = props;
  return (
    <My.Container>
      <My.ContributeButton  iconColor="dark" icon={<DataSetIcon />} buttonBackgroundColor="#EFEEEE" buttonHoverBackgroundColor="#EFEEEE" buttonColor='secondary' buttonHoverColor='primary'  />
      <My.Menu />
      <My.GeoMapsLink />
    </My.Container>
  )
};

AsideLayout.defaultProps = AsideLayoutDefaultProps;