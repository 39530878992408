import React from 'react';
import { breakpoints, theme } from '@shapeable/theme';
import { Page, PageLayoutComponent } from '@shapeable/types';
import { DownloadLink, EntityCell, EntityCellGridLayout, Grid, MarkdownContent, ShareButtons } from '@shapeable/web'
import styled, { css } from 'styled-components';
import { first, sortBy } from 'lodash';
import { PageBannerLayout } from '../entities/page-banner-layout';


// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const BannerStyles = breakpoints({
  base: css`
  `,
});

const ContentStyles = breakpoints({
  base: css`
    max-width: 900px;
    font-size: 1.1em;
    margin: ${theme.UNIT(3)} 0 ${theme.UNIT(3)};
  `,
  tablet: css`
    font-size: 1.3em;
    margin: ${theme.UNIT(5)} 0 ${theme.UNIT(3)};
  `,
});

const MarqueeGridStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});

const GridStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
    margin-bottom: ${theme.UNIT(4)};
  `,
  desktop: css`
  `,
});


const IntroStyles = breakpoints({
  base: css`
    line-height: 1.5em;
    font-size: 1em;
    padding: ${theme.UNIT(3)} ${theme.UNIT(4)} ${theme.UNIT(3, 2)};
    font-family: ${theme.FONT('sans')};
    font-weight: 400;
    color: ${theme.COLOR('dark')};
  `,
  tablet: css`
    font-size: 1.3em;
  `,
  maxContentPad: css`
    padding: 0 0 ${theme.UNIT(4)} 0;
  `,

});

const ControlsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    padding: ${theme.UNIT(4)};
    
  `,
  tablet: css`
    flex-direction: row;
    align-items: center;
  `,
  maxContentPad: css`
    padding: 0 0 ${theme.UNIT(4)} 0;
  `,

});

const ShareButtonsStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
    flex-shrink: 0;
  `,
  tablet: css`
    margin-right: ${theme.UNIT(4)};
    margin-bottom: -8px;
  `,
});

const DownloadLinkStyles = breakpoints({
  base: css`
    font-weight: 400;
    background: ${theme.COLOR('secondary')};

    &:hover {
      background: ${theme.COLOR('primary')};
    }
  `,
});


const CellStyles = breakpoints({
  base: css`
    cursor: pointer;
    font-family: ${theme.FONT('sans')};

    p {
      font-size: ${theme.FONT_SIZE(17)};
      margin-top: 0;
    }

    &:hover {
      filter: brightness(1.05);
    }
  `,
  tablet: css`
    padding: ${theme.UNIT(4)} 0;
    border-top: 1px solid ${theme.COLOR('line-subtle')};
  `,
  desktop: css`
    p {
      font-size: ${theme.FONT_SIZE(18)};
    }
  `
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Banner: styled(PageBannerLayout)`${BannerStyles}`,
    MarqueeGrid: styled(EntityCellGridLayout)`${MarqueeGridStyles}`,
    Content: styled.div`${ContentStyles}`,
      Intro: styled(MarkdownContent)`${IntroStyles}`,
    Controls: styled.div`${ControlsStyles}`,
      ShareButtons: styled(ShareButtons)`${ShareButtonsStyles}`,
      DownloadLink: styled(DownloadLink)`${DownloadLinkStyles}`,
    Grid: styled(Grid)`${GridStyles}`,
      Page: styled(EntityCell)`${CellStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity } = props;
  const { featurePages = [], marqueePages = [] } = entity;

  const intro = entity.intro && entity.intro.text;
   
  const hasIntro = !!intro;
  const hasMarqueeItems = !!marqueePages.length;
  const hasFeatureItems = !!featurePages.length;
  
  return (
    <My.Container className={className}>
      {
        <My.Banner entity={entity}  /> 
      } 
      <My.Content>
        {
          hasIntro &&
          <My.Intro text={intro} />
        }
      </My.Content>
      <My.Controls>
        <My.ShareButtons color='secondary' hoverColor='primary' />
        <My.DownloadLink url={process.env.PDF_URL} />
      </My.Controls>
      {
        hasFeatureItems && 
        <My.Grid spacing={0} maxColumns={2} desktopAutoColumns={2} tabletAutoColumns={1} items={featurePages.map(page => <My.Page key={page.id} entity={page} />)} />
      }
    </My.Container>
  )
};
