import React, { useState } from 'react';
import { Classable, Dictionary, HasChildren, MenuItem } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { filter, includes, trim, without } from 'lodash';
import { breakpoints, theme } from '@shapeable/theme';
import { ErrorList, Field, FormButton, FormLoader, Input, onSubmitNetlifyForm, Select, SelectOption, TextArea, usePage, useContributeForm, useMenuPosition, useEntityOptions, useEntity } from '@shapeable/web';
import EmailValidator from 'email-validator';
import ReCAPTCHA from 'react-google-recaptcha';
import validUrl from 'valid-url';
import { InputCheckbox } from './input-checkbox';

// -------- Types -------->

export type ContributeFormProps = Classable & HasChildren & {
}

export const ContributeFormDefaultProps: ContributeFormProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    min-height: 200px;
  `,
});

const FieldStyles = breakpoints({
  base: css`
    
  `,
});

const SelectStyles = breakpoints({
  base: css`
    width: 100%;
    font-size: ${theme.FONT_SIZE(14)};
  `,
});

const FormStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(3)};
    
  `,
});

const TextAreaStyles = breakpoints({
  base: css`
    width: 100%;
    height: 150px;
    color: ${theme.COLOR('dark')};
  `,
});

const InputStyles = breakpoints({
  base: css`
    width: 100%;
    color: ${theme.COLOR('dark')};
  `,
});

const SubmitButtonStyles = breakpoints({
  base: css`
    width: 100%;
    font-weight: 400;
    margin-top: ${theme.UNIT(2)};
    background-color: ${theme.COLOR('secondary')};

    &[disabled] {
      background-color: ${theme.COLOR('secondary')};
    }
  `,
});


const OverlayStyles = breakpoints({
  base: css`
    z-index: 100;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  `,
});


const InputCheckboxStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text')};
    margin: ${theme.UNIT(1)} 0;
    align-self: stretch;
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const LoaderStyles = breakpoints({
  base: css`
    ${OverlayStyles};
  `,
});

const ThankyouStyles = breakpoints({
  base: css`
    ${OverlayStyles};
    background: rgba(0,0,0,0.9);
    padding: ${theme.UNIT(4)};
    width: 100%;
    flex-grow: 1;
    color: ${theme.COLOR('light')};
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    p {
      margin: ${theme.UNIT(4)};
    }
  `,
});

const ThankyouMessageStyles = breakpoints({
  base: css`
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 1.25em;
  `,
});


const DoneButtonStyles = breakpoints({
  base: css`
    ${SubmitButtonStyles};
    
  `,
});

const ErrorsStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('red')};
    background: #EFEEEE;
    padding: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(4)};
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(14)};
  `,
});

const ReCAPTCHAStyles = breakpoints({
  base: css`
    margin-bottom: 10px;
  `,
});


// -------- Components -------->

const PAGE = 'page';
const TYPE = 'type';
const DETAILS = 'details';
const LINK = 'link';
const NAME = 'name';
const EMAIL = 'email';
const COMPANY = 'company';
const URL = 'url';
const FORM_NAME = 'contribute';
const AGREE = 'agree';

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Loader: styled(FormLoader)`${LoaderStyles}`,
    Thankyou: styled.div`${ThankyouStyles}`,
      ThankyouMessage: styled.div`${ThankyouMessageStyles}`,
      DoneButton: styled(FormButton).attrs({ type: 'button' })`${DoneButtonStyles}`,

    Form: styled.form`${FormStyles}`,
      Agree: styled(InputCheckbox).attrs({ id: AGREE, name: AGREE })`${InputCheckboxStyles}`,

      PageField: styled(Field).attrs({ id: PAGE, label: 'Commenting On:' })`${FieldStyles}`,
        Page: styled(Select).attrs({ id: PAGE, placeholder: 'Select a Page' })`${SelectStyles}`,
      TypeField: styled(Field).attrs({ id: TYPE, label: 'Your Contribution:' })`${FieldStyles}`,
        Type: styled(Select).attrs({ id: TYPE, placeholder: 'Contribution Type' })`${SelectStyles}`,
      DetailsField: styled(Field).attrs({ id: DETAILS })`${FieldStyles}`,
        Details: styled(TextArea).attrs({ id: DETAILS, placeholder: 'Details (Required)' })`${TextAreaStyles}`,
      LinkField: styled(Field).attrs({ id: LINK })`${FieldStyles}`,
        Link: styled(Input).attrs({ id: LINK, placeholder: 'Link (if applicable)' })`${InputStyles}`,
      
      NameField: styled(Field).attrs({ id: NAME, label: 'Your Details:' })`${FieldStyles}`,
        Name: styled(Input).attrs({ id: NAME, placeholder: 'Name (Required)' })`${InputStyles}`,
      EmailField: styled(Field).attrs({ id: EMAIL })`${FieldStyles}`,
        Email: styled(Input).attrs({ id: EMAIL, placeholder: 'Email' })`${InputStyles}`,
      CompanyField: styled(Field).attrs({ id: COMPANY })`${FieldStyles}`,
        Company: styled(Input).attrs({ id: COMPANY, placeholder: 'Company' })`${InputStyles}`,
      UrlField: styled(Field).attrs({ id: URL })`${FieldStyles}`,
        Url: styled(Input).attrs({ id: URL, placeholder: 'Company Website URL.' })`${InputStyles}`,

    Errors: styled(ErrorList)`${ErrorsStyles}`,

    ReCAPTCHA: styled(ReCAPTCHA)`${ReCAPTCHAStyles}`,
    SubmitButton: styled(FormButton)`${SubmitButtonStyles}`,
};

export const ContributeForm: React.FC<ContributeFormProps> = (props) => {
  const { className } = props;
  const form = useContributeForm();

  const { values, patchValues } = form;
  const [ token, setToken ] = useState('');
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ errorFields, setErrorFields ] = useState<string[]>([]);
  
  const page = useEntity();
  const { items } = useMenuPosition();
  
  const ref = form.useClickOutside();
  
  const pageOption = (item: MenuItem) => {
    if (!item) {
      return null;
    }

    const label = item.name;

    return {
      label,
      value: item.path,
    }
  };

  const pageOptions: SelectOption[] = items.map(pageOption);
  let pageDefaultOption = pageOption(items.find(item => item.path === page.path));

  if (!pageDefaultOption) {
    // if the page is not in the dropdown, just append it to the end
    pageDefaultOption = { label: `${page.name}`, value: page.path };
    pageOptions.push(pageDefaultOption);
  }

  const typeDefaultOption = {
    value: 'Comment',
    label: 'Comment',
  };

  const typeOptions: SelectOption[] = [
    typeDefaultOption,
    {
      value: 'Expert Suggestion',
      label: 'Expert Suggestion',
    },
    {
      value: 'Feedback',
      label: 'Feedback',
    },
    {
      value: 'Data Source',
      label: 'Data Source',
    },
    {
      value: 'Product or Solution',
      label: 'Product or Solution',
    },
    {
      value: 'Research Insight',
      label: 'Research Insight',
    },
  ];
  
  const formState: Dictionary<any> = { 
    ...values, 
    page: (values[PAGE] || pageDefaultOption),
    type: (values[TYPE] || typeDefaultOption), 
  };

  const onChange = (name: string) => (value: string | SelectOption) => { patchValues({ [name]: value }); setErrors([]); setErrorFields(without(errorFields, name)); };

  const onSubmit = async(event: React.FormEvent) => {
    event.preventDefault();
    
    const e = [];
    const ef = [];
    
    if (!token) {
      errors.push('Please check the I\'m not a robot checkbox to validate you are human');
    }

    if (trim(formState[NAME]) === '') {
      e.push('Please enter your name.');
      ef.push(NAME);
    }

    if (trim(formState[DETAILS]) === '') {
      e.push('Please describe your contribution in the Details field.');
      ef.push(DETAILS);
    }

    if (trim(formState[EMAIL]) !== '' && !EmailValidator.validate(formState[EMAIL])) {
      e.push('Please check that your email address is VALID');
      ef.push(EMAIL);
    }

    if (trim(formState[LINK]) !== '' && !validUrl.isWebUri(formState[LINK])) {
      e.push('Please provide a VALID URL in the link field');
      ef.push(LINK);
    }

    if (trim(formState[URL]) !== '' && !validUrl.isWebUri(formState[URL])) {
      e.push('Please provide a VALID URL in the Company Website URL field');
      ef.push(URL);
    }

    if (trim(formState[AGREE]) === '') {
      e.push('Please confirm you agree with our terms and privacy policy');
      ef.push(AGREE);
    }

    setErrorFields(ef);
    setErrors(e);

    if (e.length) {
      return false;
    }

    const data = { ...formState, 'g-recaptcha-response': token };
    form.startSubmit();
    const response = await onSubmitNetlifyForm(FORM_NAME, page.path, data);
    setToken('');
    form.endSubmit();
  };

  const onCaptchaChange = (token: any) => {
    setToken(token);
  };

  const isSubmitDisabled = !token;
  const hasErrors = !!errors.length;

  return (
    <My.Container ref={ref} className={className}>
      {
        form.isSubmitting && 
        <My.Loader>Please wait...</My.Loader>
      }
      {
        (form.isSubmitted) ?
        <My.Thankyou>
          <My.ThankyouMessage>
          <p>
          We've received your feedback.
          </p>
          <p>
          Thanks for your contribution!
          </p>
          </My.ThankyouMessage>
          <My.DoneButton onClick={() => { form.reset(); form.hide(); }}>Done</My.DoneButton>
        </My.Thankyou> :
        <My.Form name={FORM_NAME} onSubmit={onSubmit} method="post" data-netlify="true" data-netlify-recaptcha="true">
          <input type="hidden" name="form-name" value={FORM_NAME} />
          <My.PageField>
            <My.Page
              isClearable={false}
              onChange={onChange(PAGE)}
              value={formState[PAGE]}
              options={pageOptions}
              
            />
          </My.PageField>

          <My.TypeField>
            <My.Type 
              isClearable={false}
              onChange={onChange(TYPE)}
              value={formState[TYPE]}
              placeholder="Contribution Type"
              options={typeOptions}
            />
          </My.TypeField>

          <My.DetailsField>
            <My.Details hasError={includes(errorFields, DETAILS)} onChange={onChange(DETAILS)}>{formState[DETAILS] || ''}</My.Details>
          </My.DetailsField>

          <My.LinkField>
            <My.Link hasError={includes(errorFields, LINK)} onChange={onChange(LINK)} value={formState[LINK] || ''} />
          </My.LinkField>

          <My.NameField>
            <My.Name hasError={includes(errorFields, NAME)} onChange={onChange(NAME)} value={formState[NAME] || ''} />
          </My.NameField>

          <My.EmailField>
            <My.Email hasError={includes(errorFields, EMAIL)} onChange={onChange(EMAIL)} value={formState[EMAIL] || ''} />
          </My.EmailField>

          <My.CompanyField>
            <My.Company hasError={includes(errorFields, COMPANY)} onChange={onChange(COMPANY)} value={formState[COMPANY] || ''} />
          </My.CompanyField>

          <My.UrlField>
            <My.Url hasError={includes(errorFields, URL)} onChange={onChange(URL)} value={formState[URL] || ''} />
          </My.UrlField>

          <My.Agree onChange={onChange(AGREE)} valid={!includes(errorFields, AGREE)} checked={formState[AGREE] === 'yes'}>
          I confirm that I have read and agree to the {process.env.ORG_NAME}'s <a target="_blank" href={process.env.TERMS_PATH}>terms</a> and <a target="_blank" href={process.env.PRIVACY_PATH}>privacy policy</a>.
          </My.Agree>


          {
            process.env.SITE_RECAPTCHA_KEY && 
            <My.ReCAPTCHA
              sitekey={process.env.SITE_RECAPTCHA_KEY}
              onChange={onCaptchaChange}
            />
          }
          {
            hasErrors && 
            <My.Errors items={errors}>
            Sorry, we need a bit more information:
            </My.Errors>
          }
          <My.SubmitButton disabled={isSubmitDisabled} />
        </My.Form>
      } 
      
    </My.Container>
  )
};

ContributeForm.defaultProps = ContributeFormDefaultProps;