module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LNPYEW8DLD"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"48c20b5045f6c87589dbad541c09fb53"},
    },{
      plugin: require('../node_modules/@shapeable/gatsby-theme-shapeable-web/gatsby-browser.js'),
      options: {"plugins":[],"site":"website","fonts":["Signika+Negative:wght@300;500;600","Source+Sans+Pro:300,300i,400,400i,600,600i,700","IBM+Plex+Serif:300,300i,400,400i,700","IBM+Plex+Sans:300,300i,400,400i,700"],"entities":{"Post":{}},"siteMetadata":{"title":"Report on Aging in Orange County 2022","description":"","image":"","video":"","twitter":"","linkedin":"","facebook":"","flickr":"","tiktok":"","instagram":"","name":"","logo":"","url":"https://data.ocagingplan.org","titleTemplate":"%s - Report on Aging in Orange County 2022","siteUrl":"https://data.ocagingplan.org"}},
    }]
