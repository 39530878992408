import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page } from '@shapeable/types';
import { AspectRatio, breakpoints, theme } from '@shapeable/theme';
import { ImageEntityBackground, useEntity } from '@shapeable/web';

// -------- Types -------->

export type PageBannerLayoutProps = Classable & HasChildren & { 
  entity?: Page;
  aspectRatio?: AspectRatio;
};

export const PageBannerLayoutDefaultProps: Omit<PageBannerLayoutProps, 'entity'> = {
  aspectRatio: { base: 19 / 5 },
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('serif')};
    color: ${theme.COLOR('light')};
    background-color: #F47C21;
    font-size: ${theme.FONT_SIZE(22)};
  `,
  landscape: css`
    font-size: ${theme.FONT_SIZE(24)};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(42)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(56)};
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: ${theme.UNIT(4)};
  `,
  desktop: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(8)};
  `
});

const TitleStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-size: 1em;
    line-height: 1.1em;
    margin-bottom: 0.5em;
    width: 50%;
  `,
  desktop: css`
    width: 40%;
  `,
});

const SubtitleStyles = breakpoints({
  base: css`
    font-size: 0.6em;
    font-weight: 300;
    font-family: ${theme.FONT('sans')};
    margin-top: 0em;
    width: 50%;
  `,
  desktop: css`
    font-size: 0.5em;
  `,
});





// -------- Components -------->

const My = {
  Container: styled(ImageEntityBackground)<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Title: styled.h1`${TitleStyles}`,
      Subtitle: styled.h2`${SubtitleStyles}`,
};

export const PageBannerLayout: React.FC<PageBannerLayoutProps> = (props) => {
  const { className, children, aspectRatio } = props;
  const entity = useEntity(props.entity);
  
  const { banner, title, subtitle, name } = entity;

  return (
    <My.Container aspectRatio={aspectRatio} entity={banner} className={className}>
    <My.Body>
    <My.Title>{title || name}</My.Title>
    {
      subtitle && 
      <My.Subtitle>{subtitle}</My.Subtitle>
    }
    </My.Body>
    </My.Container>
  )
};

PageBannerLayout.defaultProps = PageBannerLayoutDefaultProps;