import React from 'react';
import styled, { css } from 'styled-components';
import { Page, PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, usePageContentLayout } from '@shapeable/web';
import { PageMainAsideLayout } from '../entities/page-main-aside-layout';
import { PageBannerLayout } from '../entities/page-banner-layout';

// -------- Child Component Props -------->

type ContainerProps = {
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const MainAsideStyles = breakpoints({
  base: css`
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  MainAside: styled(PageMainAsideLayout)`${MainAsideStyles}`,
  Banner: styled(PageBannerLayout)`${BannerStyles}`,
};

export const PageLayoutContent: PageLayoutComponent = 
(props) => {
  const { className, children } = props;
  const entity: Page = useEntity(props.entity);

  return (
    <My.Container className={className}>
      <My.Banner entity={entity} />
      <My.MainAside entity={entity} />
    </My.Container>
  )
};