import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { useContributeForm, Icons, HidePanelButton, HidePanelButtonProps } from '@shapeable/web';
import { ContributeForm } from './contribute-form';
import { DataSetIcon } from './data-set-icon';

// -------- Types -------->

export type ContributeFormPanelProps = Classable & HasChildren & {
}

export const ContributeFormPanelDefaultProps: ContributeFormPanelProps = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background: #EFEEEE;
    box-sizing: border-box;
    position: relative;
    height: calc(100vh + 40px);
    max-height: 1250px;

    label {
      color: ${theme.COLOR('text')};
    }
  `,
  desktop: css`
    height: 100vh;
    max-height: 1000px;
  `
});



const HideButtonStyles = breakpoints({
  base: css`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: none;

  `,
  desktop: css`
    display: flex;
  `,
});

const HideButtonHorizontalStyles = breakpoints({
  base: css`
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%;
  `,
  desktop: css`
    display: none;
  `,
});

const FormStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)} ${theme.UNIT(4)};
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 42px;
    height: calc(100vh - 100px);
    overflow-y: auto;
  `,
  desktop: css`
    top: 0px;
    left: 54px;
    height: 100%;
    width: calc(100% - 54px);
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<any>`${ContainerStyles}`,
    HideButton: styled(HidePanelButton)`${HideButtonStyles}`,
    Form: styled(ContributeForm)`${FormStyles}`,
    HideButtonHorizontal: styled(HidePanelButton)`${HideButtonHorizontalStyles}`,
};

export const ContributeFormPanel: React.FC<ContributeFormPanelProps> = (props) => {
  const { className } = props;
  const contributeForm = useContributeForm();

  const onContainerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const colorProps: Partial<HidePanelButtonProps> = { 
    backgroundColor: '#EFEEEE',
    hoverBackgroundColor: '#F2F1F1',
    borderColor: 'line-mid',
    color: 'text',
    iconColor: 'secondary',
    hoverIconColor: 'primary', 
  };

  return (
    <My.Container onClick={onContainerClick} className={className}>
      <My.HideButtonHorizontal {...colorProps} icon={<DataSetIcon />} label="HIDE" onClick={contributeForm.hideOnClick} />
      <My.HideButton {...colorProps} icon={<DataSetIcon />} label="HIDE" isVertical onClick={contributeForm.hideOnClick} />
      <My.Form />
    </My.Container>
  )
};

ContributeFormPanel.defaultProps = ContributeFormPanelDefaultProps;