import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Logo, MenuNavigation, PoweredByShapeableLogo, useEntity, useFooterMenu, useLink } from '@shapeable/web';
// -------- Types -------->

export type PageFooterLayoutProps = Classable & HasChildren & { 
  entity?: Page;
};

export const PageFooterLayoutDefaultProps: Omit<PageFooterLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background-color: ${theme.BG('app-chrome')};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    align-self: stretch;
    font-size: ${theme.FONT_SIZE(12)};
    padding: ${theme.UNIT(4)} ${theme.UNIT(4)};
  `,
  tablet: css`
    flex-direction: row;
    font-size: ${theme.FONT_SIZE(13)};
    align-items: center;
    padding: ${theme.UNIT(6)} ${theme.UNIT(4)};
  `,
  desktopLarge: css`
    padding: ${theme.UNIT(8)} ${theme.UNIT(6)};
  `,

});

const LogoStyles = breakpoints({
  base: css`
    height: 60px;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.UNIT(6)};
  `,
  tablet: css`
    margin-bottom: 0;
    align-items: center;
  `,
});


const NavigationStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-size: 1em;
    text-transform: uppercase;
    height: ${theme.UNIT(6)};
    margin-top: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(2)};
    li:first-child {
      margin-left: 0;
    }
  `,
  tablet: css`
    margin-top: 0;
    
  `
});

const CreditLogoStyles = breakpoints({
  base: css`
    width: 160px;
  `,
  tablet: css`
    width: 180px;
  `,
});

const CopyrightStyles = breakpoints({
  base: css`
    font-weight: 300;
    color: ${theme.COLOR('text')};
    margin-top: ${theme.UNIT(4)};
  `,
  tablet: css`
    margin-top: 0;
  `
});

const BoundaryStyles = breakpoints({
  base: css`
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `
});


// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Boundary: styled.div`${BoundaryStyles}`,
    Logo: styled(Logo)`${LogoStyles}`,
    CreditLogo: styled(PoweredByShapeableLogo)`${CreditLogoStyles}`,

    Body: styled.div`${BodyStyles}`,
      Navigation: styled(MenuNavigation)`${NavigationStyles}`,
      Copyright: styled.span`${CopyrightStyles}`,
};

export const PageFooterLayout: React.FC<PageFooterLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const menu = useFooterMenu();

  const { Link } = useLink();
  const orgName = process.env.ORG_NAME;

  return (
    <My.Container className={className}>
      <My.Boundary>
      <My.Logo variant="inverted" />
      <My.Body>
        {
          menu &&
          <My.Navigation color="secondary" activeColor='primary' hoverColor='primary' stackOnMobile={false} showDividers dividerColor="secondary" entity={menu} />
        }
        <My.Copyright>&copy; {(new Date()).getFullYear()} {orgName}. All rights reserved</My.Copyright>
      </My.Body>
      <Link href="https://shapeable.ai">
        <My.CreditLogo />
      </Link>
      {children}
      </My.Boundary>
    </My.Container>
  )
};

PageFooterLayout.defaultProps = PageFooterLayoutDefaultProps;