import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, entityHasDescription, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';

import IMAGE_SRC from '../../images/visit-geomaps.jpg';
import { useLang, useLink } from '@shapeable/web';

// -------- Types -------->

export type GeoMapsLinkProps = Classable & HasChildren & {
}

export const GeoMapsLinkDefaultProps: GeoMapsLinkProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(18)};

    a {
      font-weight: 400;
      font-family: ${theme.FONT('sans')};
      color: ${theme.COLOR('secondary')};
      text-decoration: none;
      &:hover {
        color: ${theme.COLOR('primary')};
      }
    }
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    padding: ${theme.UNIT(2.5)};
    flex-direction: row;
    align-items: center;
  `,
});

const ImageStyles = breakpoints({
  base: css`
    width: 45%;
    max-width: 120px;
  `,
});

const TextStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
    
    span {
      font-weight: 300;
      color: ${theme.COLOR('text')};
    }
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Image: styled.img`${ImageStyles}`,
      Text: styled.span`${TextStyles}`,
};

export const GeoMapsLink: React.FC<GeoMapsLinkProps> = (props) => {
  const { className } = props;
  
  const { Link } = useLink();
  const t = useLang();
  return (
    <My.Container className={className}>
    <Link href={process.env.GEO_MAP_URL}>
      <My.Body>
        <My.Image src={IMAGE_SRC} />
        <My.Text>
        <span>{t('Visit:')}<br /></span>
        {t('OCSPA Geo Maps')}
        </My.Text>
      </My.Body>
    </Link>
    </My.Container>
  )
};

GeoMapsLink.defaultProps = GeoMapsLinkDefaultProps;